<template>
  <div
    :id="`post-box-${post.id}-${typeOfRender}`"
    class="h-full w-full flex justify-center items-center bg-black bg-cover relative rounded-sm overflow-hidden bg-none"
    :style="[
      post.backgroundColor
        ? { backgroundColor: `${post.backgroundColor}` }
        : null
    ]"
  >
    <template v-if="postHasImage()">
      <img
        :src="post.imageUrl"
        alt="post-image"
        class="h-full w-full object-cover"
      />
    </template>
    <template v-if="postHasText()">
      <div
        :id="`text-box-${post.id}-${typeOfRender}`"
        class="text-box flex absolute w-max text-center whitespace-pre-wrap post-text-std h-auto leading-none"
      >
        <p>
          <span
            :id="`text-${post.id}-${typeOfRender}`"
            class="p-0"
            :style="postStyle"
            >{{ post.text }}</span
          >
        </p>
      </div>
    </template>
    <template v-if="!postHasVideo()">
      <!-- this slot is to render the foother controls bg (the parent decide the shape) -->
      <slot />
    </template>
    <template v-if="postHasVideo()">
      <VideoRender
        class="z-0"
        :showControls="showControls"
        :videoUrl="post.videoUrl"
        :autoplay="typeOfRender === 'single'"
        @playbackFinished="$emit('playbackFinished')"
        @videoDurationUpdated="
          e => {
            $emit('videoDurationUpdated', e);
          }
        "
        @videoPlaying="
          e => {
            $emit('videoPlaying', e);
          }
        "
        :showPostControls="typeOfRender === 'single'"
        :forcePause="pause"
      >
        <!-- this slot is to render the foother controls bg (the parent decide the shape) -->
        <slot />
      </VideoRender>
    </template>
  </div>
</template>
<script>
import VideoRender from "@/components/shared/VideoRender.vue";

export default {
  components: {
    VideoRender
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    typeOfRender: {
      type: String // list - single
    },
    showControls: {
      type: Boolean,
      default: false
    },
    pause: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      buttonVideo: "play"
    };
  },
  mounted() {
    this.recalculateFontSize();
  },
  computed: {
    postStyle() {
      let style = this.post.textColor ? `color: ${this.post.textColor};` : "";
      style =
        style +
        (this.post.textColor && this.post.textColor === "white"
          ? "background-Color: #000000A3;"
          : "background-color: #ffffff9e;");
      return style;
    }
  },
  methods: {
    recalculateFontSize() {
      const box = document.getElementById(
        `post-box-${this.post.id}-${this.typeOfRender}`
      );
      const boxWidth = box.offsetWidth;
      const percentage = (boxWidth * 100) / 375;
      const textBox = document.getElementById(
        `text-${this.post.id}-${this.typeOfRender}`
      );
      if (textBox) {
        const textParentBox = document.getElementById(
          `text-box-${this.post.id}-${this.typeOfRender}`
        );
        textBox.style.fontSize = `${percentage}%`;
        textParentBox.style.lineHeight = `${(55 * percentage) / 100}px`;
        const boxHeight = box.offsetHeight;
        const x = (this.post.textPlacement.x * boxWidth) / 100;
        const y = (this.post.textPlacement.y * boxHeight) / 100;
        textParentBox.style.top = `${y}px`;
        textParentBox.style.left = `${x}px`;
      }
    },
    postHasImage() {
      return this.post.imageUrl ?? false;
    },
    postHasText() {
      return this.post.text ?? false;
    },
    postHasVideo() {
      return this.post.videoUrl ?? false;
    }
  }
};
</script>
<style lang="postcss" scoped>
span {
  padding: 0 5px;
  border-radius: 5px;
  box-decoration-break: clone;
}

.text-box {
  font-size: 24px;
  line-height: 55px;
}
</style>
