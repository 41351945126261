<template>
  <button
    @click.stop="$emit('click')"
    class="rounded-full bg-white w-20 h-20 flex justify-center items-center"
  >
    <img class="ml-1 h-8" src="@/assets/img/icons/play-pink.svg" alt="play" />
  </button>
</template>
<script>
export default {
  name: "PlayBtn"
};
</script>
