import { render, staticRenderFns } from "./PostViewMenu.vue?vue&type=template&id=52956085&scoped=true&"
import script from "./PostViewMenu.vue?vue&type=script&lang=js&"
export * from "./PostViewMenu.vue?vue&type=script&lang=js&"
import style0 from "./PostViewMenu.vue?vue&type=style&index=0&id=52956085&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52956085",
  null
  
)

export default component.exports