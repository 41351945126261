<template>
  <div class="disabled-post font-tiny top-0 left-0 z-10">
    <BaseAlertModal v-model="showPostStatusInfoModal" class="z-50">
      <div class="flex flex-col text-black">
        <h5 class="mb-2">
          {{ $t("message.post-removed") }}
        </h5>
        <div class="font-body2">
          This is some text that will explain what has happened and why. It
          should be short and to the point. TODO*
        </div>
      </div>
    </BaseAlertModal>
    <template v-if="post.publishState == 'queued'">
      {{ $t("message.pending") }}
    </template>
    <template v-if="post.publishState == 'censored'">
      <div class="flex flex-row">
        <div class="mr-2">
          {{ $t("message.post-in-violation") }}
        </div>
      </div>
    </template>
    <template v-if="post.publishState == 'hidden'">
      <div class="flex flex-row">
        <div class="mr-2">
          {{ $t("message.deleted-by-creator") }}
        </div>
      </div>
    </template>
    <template v-if="post.publishState == 'deleted'">
      <div class="flex flex-row">
        <div class="mr-2">
          {{ $t("message.removed-by-admin") }}
        </div>
        <button @click="showPostStatusInfoModal = true">
          <img src="@/assets/img/icons/info.svg" alt="info" />
        </button>
      </div>
    </template>
    <template v-if="post.publishState == 'cancelled'">
      <div class="flex flex-row">
        <div class="mr-2">
          {{ $t("message.cancelled-by-creator") }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showPostStatusInfoModal: false
    };
  }
};
</script>
