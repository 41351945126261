<template>
  <div id="post-wrapper" class="relative h-full bg-none">
    <div
      :class="{ 'with-controls': showListControls }"
      class="md:grid-cols-3 modal-post-grid grid h-full w-full"
    >
      <div
        v-if="showListControls"
        class="controls hidden h-full justify-center items-center"
      >
        <button
          v-if="showListControls"
          @click="requestPrev()"
          class="w-10 h-10 rounded-full overflow-hidden bg-gray-700 flex justify-center items-center"
        >
          <div class="arrow left"></div>
        </button>
      </div>
      <div class="relative md:rounded-md overflow-hidden" v-if="!loading">
        <PostViewMenu v-model="showPostMenu" v-if="post" :post="post" />
        <div class="controls-bg z-10" />
        <div
          v-if="showOptions"
          class="absolute px-4 mt-8 w-full flex justify-between z-30 h-9"
        >
          <div class="text-left">
            <img
              class="icon-shadow w-10"
              @click="share()"
              src="@/assets/img/icons/dotdotdot.svg"
              alt="share"
            />
          </div>
          <div class="w-24 h-full flex items-center justify-center">
            <PostProgressBar
              v-if="progressBarId"
              :key="progressBarId"
              :time="loadBarTime"
              :pause="showPostMenu"
            />
          </div>
          <div class="text-left">
            <button
              @click="close()"
              class="w-12 h-12 flex justify-end items-start tp-2"
            >
              <img
                src="@/assets/img/icons/exit-icon-white.svg"
                alt="close"
                class="icon-shadow"
              />
            </button>
          </div>
        </div>
        <PostRender
          class="top-0 left-0 z-0"
          v-if="post"
          :key="post.id"
          :post="post"
          typeOfRender="single"
          :showControls="showControls"
          @playbackFinished="requestNext()"
          @videoDurationUpdated="videoDurationUpdated"
          @videoPlaying="resetProgressBar"
          :pause="showPostMenu"
        >
          <div class="footer-controls-bg z-10" />
        </PostRender>
        <template v-if="post.publishState !== 'published'">
          <div
            class="disabled-post-bg h-full w-full absolute top-0 left-0"
          ></div>
          <DisabledPostStatus :post="post" />
        </template>
        <div
          v-if="showListControls"
          class="w-full grid grid-cols-2 absolute bottom-16 left-0 h-5/6 z-20"
        >
          <div @click="requestPrev()" />
          <div @click="requestNext()" />
        </div>
        <div
          class="w-64 flex flex-row absolute bottom-0 pl-6 pb-5 pr-6 z-20 items-center"
        >
          <div class="flex-grow">
            <button
              v-if="post && post.creator"
              class="flex flex-row items-center z-30"
              @click="
                $router
                  .push({
                    name: 'ProfilePage',
                    params: { profileId: post.creator.id }
                  })
                  .catch(() => {})
              "
            >
              <div
                id="profile-icon"
                class="w-10 h-10 rounded-full overflow-hidden"
              >
                <img
                  v-if="post.creator.profileImageUrl"
                  class="w-full h-full"
                  :src="post.creator.profileImageUrl"
                  :alt="post.creator.displayName"
                />
              </div>
              <div class="font-body2 text-white pl-4 capitalize">
                {{ post.creator.displayName }}
              </div>
            </button>
          </div>
        </div>
        <slot />
      </div>
      <div
        v-else
        class="h-full w-full md:rounded-md bg-black flex justify-center items-center text-white"
      >
        <AppleSpinner />
      </div>
      <div
        v-if="showListControls"
        class="controls hidden h-full justify-center items-center"
      >
        <button
          v-if="showListControls"
          @click="requestNext()"
          class="w-10 h-10 rounded-full overflow-hidden bg-gray-700 flex justify-center items-center"
        >
          <div class="arrow right"></div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import PostProgressBar from "@/components/shared/PostProgressBar.vue";
import PostRender from "@/components/posts/PostRender.vue";
import PostViewMenu from "@/components/posts/PostViewMenu.vue";
import AppleSpinner from "@/components/shared/AppleSpinner.vue";
import DisabledPostStatus from "@/components/posts/DisabledPostStatus.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    PostRender,
    PostViewMenu,
    AppleSpinner,
    DisabledPostStatus,
    PostProgressBar
  },
  props: {
    post: {
      type: [Object, null]
    },
    showOptions: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    showListControls: {
      type: Boolean,
      required: false,
      default: false
    },
    showControls: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      showPostMenu: false,
      showPostStatusInfoModal: false,
      loadBarTime: 6,
      progressBarId: this.post?.id,
      skipAuto: false
    };
  },
  mounted() {
    this.nextTimer();
  },
  watch: {
    post: {
      deep: true,
      handler() {
        this.nextTimer();
        this.loadBarTime = 6;
        if (!this.post.videoUrl) {
          this.progressBarId = this.post.id;
        }
      }
    },
    showPostMenu(val) {
      if (val) {
        this.skipAuto = true;
      }
    }
  },
  methods: {
    share() {
      this.showPostMenu = true;
    },
    close() {
      this.showPostMenu = false;
      this.showPostStatusInfoModal = false;
      this.$emit("close");
    },
    requestPrev() {
      this.$emit("requestedPrevious");
    },
    requestNext() {
      this.skipAuto = false;
      this.$emit("requestedNext");
    },
    nextTimer() {
      const callerPost = this.post.id;
      if (!this.post.videoUrl) {
        setTimeout(() => {
          if (this.post.id == callerPost && !this.skipAuto) {
            this.requestNext();
          }
        }, 6000);
      }
    },
    videoDurationUpdated(time) {
      this.loadBarTime = time;
    },
    resetProgressBar(status = true) {
      if (status) {
        this.progressBarId = this.post.id + uuidv4();
      } else {
        this.progressBarId = null;
      }
    }
  }
};
</script>
<style lang="postcss" scoped>
.controls-bg {
  height: 300px;
  width: 100%;
  background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
  opacity: 0.6;
  transform: matrix(1, 0, 0, -1, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
}

.footer-controls-bg {
  background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
  opacity: 0.7;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  width: 100%;
  height: 250px;
  position: fixed;
  bottom: 0;
  left: 0;

  @apply md:absolute;
}

.disabled-post-bg {
  background-color: #0009;
}

.disabled-post {
  top: 50%;

  @apply text-white absolute text-center justify-center flex w-full z-10 items-center;
}

.modal-post-grid {
  width: 100%;
  grid-template-columns: 1fr;

  &.with-controls {
    @media screen and (min-width: 600px) {
      width: fit-content;
      grid-template-columns: 70px var(--desktop-post-width) 70px;

      .controls {
        @apply flex;
      }
    }
  }
}

.arrow {
  border: solid white;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 5px;

  &.left {
    transform: rotate(135deg);
  }

  &.right {
    transform: rotate(-45deg);
  }
}
</style>
