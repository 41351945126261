<template>
  <div class="z-40">
    <BaseFlexModal class="flex" v-model="showModalInt" :showHeaders="false">
      <div class="w-72 h-auto overflow-hidden">
        <div class="absolute right-4 top-4">
          <img
            @click="closeOrReturn"
            class="w-5 h-5"
            src="@/assets/img/icons/exit-icon.svg"
            alt="exit"
          />
        </div>
        <ul v-if="showMenu" class="options-list w-72 px-6">
          <li
            class="h-16 text-center font-subtitle2 text-blue-900 flex justify-center items-center"
          >
            <button>
              Report
            </button>
          </li>
          <li
            v-if="post.publishState === 'published'"
            class="h-16 text-center font-subtitle2 text-blue-900 flex justify-center items-center"
          >
            <button @click="showShareMenu()">
              {{ $t("message.share-to") }}...
            </button>
          </li>
          <li
            class="h-16 text-center font-subtitle2 text-red-900 flex justify-center items-center"
          >
            <button>
              Delete Post
            </button>
          </li>
        </ul>
        <SocialSharePost v-if="showShareBox" :post="post" />
      </div>
    </BaseFlexModal>
  </div>
</template>
<script>
import SocialSharePost from "@/components/posts/SocialSharePost.vue";
export default {
  components: {
    SocialSharePost
  },
  name: "PostViewMenu",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    post: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showModalInt: this.value,
      showMenu: true,
      showShareBox: false
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.showShareBox = false;
        this.showMenu = true;
      }
      this.showModalInt = val;
    },
    showModalInt(val) {
      if (val != this.value) {
        this.showShareBox = false;
        this.$emit("input", val);
      }
    }
  },
  computed: {
    postUrl() {
      let props = this.$router.resolve({
        name: "PostPage",
        params: { id: this.post.id }
      });
      return process.env.VUE_APP_WEB_URL + props.href;
    }
  },
  methods: {
    closeOrReturn() {
      this.showModalInt = false;
    },
    showShareMenu() {
      try {
        const shareData = {
          title: "GOL",
          text: `Look at this Game of our Life Post I have found from: ${this.post.creator.displayName}`,
          url: this.postUrl
        };
        navigator.share(shareData);
      } catch (err) {
        this.showMenu = false;
        this.showShareBox = true;
      }
    }
  }
};
</script>
<style lang="postcss" scoped>
.options-list {
  li {
    @apply border-t border-gray-200;
  }

  li:first-child {
    border-top: none;
  }

  li:last-child {
    border-bottom: none;
  }
}
</style>
