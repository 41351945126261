var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-render-box h-full w-full relative overflow-hidden",class:{
    'hide-controls': !_vm.showControlsClass && _vm.playing,
    'is-desktop': !_vm.isTouchDevice,
    'is-mobile': _vm.isTouchDevice
  },attrs:{"id":("video-render-" + _vm.id)},on:{"click":function($event){return _vm.showTempControls(true)}}},[_c('div',{staticClass:"absolute top-0 left-0 w-full h-full z-0 bg-center",style:(_vm.bgStyle)},[_vm._t("default")],2),_c('div',{ref:"videoBox",staticClass:"invisible relative top-0 left-0 w-full h-full",attrs:{"id":("video-box-" + _vm.id)}},[_c('video',{ref:"video",staticClass:"h-full w-full bg-black object-cover",attrs:{"src":("" + _vm.videoUrl),"playsinline":"","preload":"metadata","controls":_vm.nativeControls,"autoplay":_vm.autoplay,"controlsList":"nodownload","oncontextmenu":"return false;","crossorigin":"anonymous"}},_vm._l((_vm.captions),function(caption,index){return _c('track',{key:index,ref:("track-" + index),refInFor:true,attrs:{"src":caption.url,"kind":"subtitles","srclang":caption.lang,"label":caption.label}})}),0),_vm._t("default"),(_vm.showCustomControls)?_c('div',{staticClass:"bg-controls"}):_vm._e(),(_vm.showCustomControls)?_c('div',{staticClass:"flex flex-row absolute bottom-0 text-white w-full px-2",attrs:{"id":"video-controls"}},[_c('div',{staticClass:"flex-grow"},[(!_vm.playing)?_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPlay),expression:"showPlay"}],staticClass:"w-11 h-11 flex justify-center items-center",on:{"click":function($event){$event.stopPropagation();return (function () {
              _vm.play();
              _vm.showTempControls();
            }).apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/play.svg")}})]):_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPause),expression:"showPause"}],staticClass:"w-11 h-11 flex justify-center items-center",on:{"click":function($event){$event.stopPropagation();return _vm.pause()}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/pause.svg")}})])]),(_vm.captions.length > 0)?_c('button',{staticClass:"w-11 h-11 flex justify-center items-center",on:{"click":function($event){$event.stopPropagation();return _vm.showCaption()}}},[(!_vm.showingCaptions)?_c('img',{attrs:{"src":require("@/assets/img/icons/captions-disabled.svg")}}):_c('img',{attrs:{"src":require("@/assets/img/icons/captions.svg")}})]):_vm._e(),(!_vm.mute)?_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVolume),expression:"showVolume"}],staticClass:"w-11 h-11 flex justify-center items-center",on:{"click":function($event){$event.stopPropagation();return _vm.setMute()}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/volume.svg")}})]):_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVolumeMuted),expression:"showVolumeMuted"}],staticClass:"w-11 h-11 flex justify-center items-center",on:{"click":function($event){$event.stopPropagation();return _vm.unmute()}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/volume-mute.svg")}})])]):_vm._e()],2),(!_vm.videoMode && _vm.videoUrl)?_c('PlayBtn',{staticClass:"absolute center-play-btn",on:{"click":function($event){return _vm.play()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }