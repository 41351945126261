import { render, staticRenderFns } from "./VideoRender.vue?vue&type=template&id=74002891&scoped=true&"
import script from "./VideoRender.vue?vue&type=script&lang=js&"
export * from "./VideoRender.vue?vue&type=script&lang=js&"
import style0 from "./VideoRender.vue?vue&type=style&index=0&id=74002891&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74002891",
  null
  
)

export default component.exports