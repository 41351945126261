var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full w-full flex justify-center items-center bg-black bg-cover relative rounded-sm overflow-hidden bg-none",style:([
    _vm.post.backgroundColor
      ? { backgroundColor: ("" + (_vm.post.backgroundColor)) }
      : null
  ]),attrs:{"id":("post-box-" + (_vm.post.id) + "-" + _vm.typeOfRender)}},[(_vm.postHasImage())?[_c('img',{staticClass:"h-full w-full object-cover",attrs:{"src":_vm.post.imageUrl,"alt":"post-image"}})]:_vm._e(),(_vm.postHasText())?[_c('div',{staticClass:"text-box flex absolute w-max text-center whitespace-pre-wrap post-text-std h-auto leading-none",attrs:{"id":("text-box-" + (_vm.post.id) + "-" + _vm.typeOfRender)}},[_c('p',[_c('span',{staticClass:"p-0",style:(_vm.postStyle),attrs:{"id":("text-" + (_vm.post.id) + "-" + _vm.typeOfRender)}},[_vm._v(_vm._s(_vm.post.text))])])])]:_vm._e(),(!_vm.postHasVideo())?[_vm._t("default")]:_vm._e(),(_vm.postHasVideo())?[_c('VideoRender',{staticClass:"z-0",attrs:{"showControls":_vm.showControls,"videoUrl":_vm.post.videoUrl,"autoplay":_vm.typeOfRender === 'single',"showPostControls":_vm.typeOfRender === 'single',"forcePause":_vm.pause},on:{"playbackFinished":function($event){return _vm.$emit('playbackFinished')},"videoDurationUpdated":function (e) {
          _vm.$emit('videoDurationUpdated', e);
        },"videoPlaying":function (e) {
          _vm.$emit('videoPlaying', e);
        }}},[_vm._t("default")],2)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }