export default {
  methods: {
    shareNative(contentUrl, contentText, onNativeError) {
      const shareData = {
        title: "GOL",
        text: contentText,
        url: contentUrl
      };
      try {
        navigator.share(shareData);
      } catch {
        onNativeError();
        return;
      }

      this.sendSharedEvent("native");
    },
    shareToTwitter(contentUrl, contentText, hashtags) {
      const url = `https://twitter.com/share?url=${contentUrl}&text=${encodeURI(
        contentText
      )}&hashtags=${hashtags}`;
      window.open(url, "_blank").focus();
      this.sendSharedEvent("twitter");
    },
    shareToFacebook(contentUrl, contentText) {
      console.log(contentUrl, contentText);
      const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
        contentUrl
      )}&src=GameOfOurLives`;
      this.sendSharedEvent("facebook");
      window.open(url, "_blank").focus();
    },
    copyLink() {
      try {
        let testingCodeToCopy = document.querySelector("#post-url");
        testingCodeToCopy.setAttribute("type", "text");
        testingCodeToCopy.select();
        var successful = document.execCommand("copy");
        testingCodeToCopy.setAttribute("type", "hidden");
        window.getSelection().removeAllRanges();
        if (successful) {
          alert("Post link was copied to your clipboard");
        } else {
          throw "error";
        }
      } catch (err) {
        alert("Oops, unable to copy");
      }
      this.sendSharedEvent("clipboard");
    },
    shareToTikTok() {},
    shareToInstagram() {},
    sendSharedEvent(destination) {
      this.$analytics.sharedToExternal(this.type, this.data, destination);
    }
  }
};
