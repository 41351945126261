<template>
  <div class="w-72">
    <input type="hidden" id="post-url" :value="postUrl" />
    <ul class="options-list w-72 px-6">
      <li class="h-16 text-left font-subtitle2 text-blue-900 flex items-center">
        <button class="flex flex-row items-center">
          <img
            class="w-6 mr-4"
            src="@/assets/img/icons/social-blue/instagram.svg"
            alt="instagram"
          />
          <div class="font-subtitle2">
            Instagram (WIP)
          </div>
        </button>
      </li>
      <li class="h-16 text-left font-subtitle2 text-blue-900 flex items-center">
        <button
          @click="shareToTwitter(this.postUrl, shareText)"
          class="flex flex-row items-center"
        >
          <img
            class="w-6 mr-4"
            src="@/assets/img/icons/social-blue/twitter.svg"
            alt="twitter"
          />
          <div class="font-subtitle2">
            Twitter
          </div>
        </button>
      </li>
      <li class="h-16 text-left font-subtitle2 text-blue-900 flex items-center">
        <button
          @click="shareToFacebook(this.postUrl)"
          class="flex flex-row items-center"
        >
          <img
            class="w-6 mr-4"
            src="@/assets/img/icons/social-blue/facebook.svg"
            alt="facebook"
          />
          <div class="font-subtitle2">
            Facebook
          </div>
        </button>
      </li>
      <li class="h-16 text-left font-subtitle2 text-blue-900 flex items-center">
        <button class="flex flex-row items-center">
          <img
            class="w-6 mr-4"
            src="@/assets/img/icons/social-blue/tiktok.svg"
            alt="tik tok"
          />
          <div class="font-subtitle2">
            TikTok (WIP)
          </div>
        </button>
      </li>
      <li class="h-16 text-left font-subtitle2 text-blue-900 flex items-center">
        <button class="flex flex-row items-center" @click="copyLink()">
          <img
            class="w-6 mr-4"
            src="@/assets/img/icons/social-blue/dotdotdot.svg"
            alt="link"
          />
          <div class="font-subtitle2">
            {{ $t("message.copy-link") }}
          </div>
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
import SocialShareMixin from "@/mixins/SocialShareMixin.js";
export default {
  mixins: [SocialShareMixin],
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      responseShare: null,
      shareText: "Have a look to my new post"
    };
  },
  computed: {
    postUrl() {
      let props = this.$router.resolve({
        name: "PostPage",
        params: { id: this.post.id }
      });
      return process.env.VUE_APP_WEB_URL + props.href;
    }
  },
  methods: {
    copyLink() {
      try {
        let testingCodeToCopy = document.querySelector("#post-url");
        testingCodeToCopy.setAttribute("type", "text");
        testingCodeToCopy.select();
        var successful = document.execCommand("copy");
        testingCodeToCopy.setAttribute("type", "hidden");
        window.getSelection().removeAllRanges();
        if (successful) {
          alert("Post link was copied to your clipboard");
        } else {
          throw "error";
        }
      } catch (err) {
        alert("Oops, unable to copy");
      }
    }
  }
};
</script>
<style lang="postcss" scoped>
.options-list {
  li {
    @apply border-t border-gray-200;
  }

  li:first-child {
    border-top: none;
  }

  li:last-child {
    border-bottom: none;
  }
}
</style>
