<template>
  <div class="progress-container">
    <div class="progress2 progress-moved">
      <div class="bar" :class="{ paused: pause }"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    time: {
      required: false,
      default: 6
    },
    pause: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    time(val) {
      const pb = document.getElementsByClassName("bar");
      pb[0].style["-webkit-animation-duration"] = `${Number(val.toFixed(0))}s`;
    }
  },
  mounted() {
    const pb = document.getElementsByClassName("bar");
    pb[0].style["-webkit-animation-duration"] = `${Number(
      this.time.toFixed(0)
    )}s`;
  }
};
</script>
<style lang="postcss" scoped>
.progress-container {
  width: 100%;
  text-align: center;
}

.progress2 {
  padding: 0;
  border-radius: 30px;
  background: rgb(0 0 0 / 25%);
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 25%), 0 1px rgb(255 255 255 / 8%);
  background-color: #8c8c8c6e;
}

.bar {
  height: 4px;
  border-radius: 30px;
  background-image: linear-gradient(
    to bottom,
    rgb(255 255 255 / 30%),
    rgb(255 255 255 / 5%)
  );
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-moved .bar {
  width: 100%;
  background-color: white;
  animation: progress-animation 6s linear;
}

@keyframes progress-animation {
  0% {
    width: 0%;
    background-color: white;
  }

  100% {
    width: 100%;
    background-color: white;
  }
}

.paused {
  animation-play-state: paused !important;
}
</style>
