<template>
  <div
    @click="showTempControls(true)"
    :id="`video-render-${id}`"
    class="video-render-box h-full w-full relative overflow-hidden"
    :class="{
      'hide-controls': !showControlsClass && playing,
      'is-desktop': !isTouchDevice,
      'is-mobile': isTouchDevice
    }"
  >
    <div
      class="absolute top-0 left-0 w-full h-full z-0 bg-center"
      :style="bgStyle"
    >
      <slot />
    </div>
    <div
      :id="`video-box-${id}`"
      ref="videoBox"
      class="invisible relative top-0 left-0 w-full h-full"
    >
      <video
        :ref="`video`"
        class="h-full w-full bg-black object-cover"
        :src="`${videoUrl}`"
        playsinline
        preload="metadata"
        :controls="nativeControls"
        :autoplay="autoplay"
        controlsList="nodownload"
        oncontextmenu="return false;"
        crossorigin="anonymous"
      >
        <track
          v-for="(caption, index) in captions"
          :ref="`track-${index}`"
          :key="index"
          :src="caption.url"
          kind="subtitles"
          :srclang="caption.lang"
          :label="caption.label"
        />
      </video>
      <slot />
      <div v-if="showCustomControls" class="bg-controls"></div>
      <div
        v-if="showCustomControls"
        id="video-controls"
        class="flex flex-row absolute bottom-0 text-white w-full px-2"
      >
        <div class="flex-grow">
          <button
            v-show="showPlay"
            class="w-11 h-11 flex justify-center items-center"
            v-if="!playing"
            @click.stop="
              () => {
                play();
                showTempControls();
              }
            "
          >
            <img src="@/assets/img/icons/play.svg" />
          </button>
          <button
            v-show="showPause"
            class="w-11 h-11 flex justify-center items-center"
            @click.stop="pause()"
            v-else
          >
            <img src="@/assets/img/icons/pause.svg" />
          </button>
        </div>
        <button
          class="w-11 h-11 flex justify-center items-center"
          @click.stop="showCaption()"
          v-if="captions.length > 0"
        >
          <img
            src="@/assets/img/icons/captions-disabled.svg"
            v-if="!showingCaptions"
          />
          <img src="@/assets/img/icons/captions.svg" v-else />
        </button>
        <button
          v-show="showVolume"
          class="w-11 h-11 flex justify-center items-center"
          v-if="!mute"
          @click.stop="setMute()"
        >
          <img src="@/assets/img/icons/volume.svg" />
        </button>
        <button
          v-show="showVolumeMuted"
          class="w-11 h-11 flex justify-center items-center"
          v-else
          @click.stop="unmute()"
        >
          <img src="@/assets/img/icons/volume-mute.svg" />
        </button>
      </div>
    </div>
    <PlayBtn
      v-if="!videoMode && videoUrl"
      @click="play()"
      class="absolute center-play-btn"
    />
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import PlayBtn from "@/components/shared/PlayBtn.vue";
function iOS() {
  var userAgent = window.navigator.userAgent;

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) return true;
  if (
    userAgent.match(/Mac/i) &&
    window.navigator.maxTouchPoints &&
    window.navigator.maxTouchPoints > 0
  )
    return true;

  return false;
}
export default {
  components: {
    PlayBtn
  },
  props: {
    videoUrl: {
      type: String,
      default: ""
    },
    captions: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    coverImgUrl: {
      type: String,
      default: ""
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    nativeControls: {
      type: Boolean,
      default: false
    },
    showControls: {
      type: Boolean,
      default: true
    },
    showPostControls: {
      type: Boolean,
      default: false
    },
    forcePause: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isTouchDevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    },
    bgStyle() {
      if (this.coverImgUrl === "") {
        return "";
      }

      return `background-image: url('${this.coverImgUrl}'); background-size: cover;`;
    },
    showCustomControls() {
      return (this.showControls || this.showPostControls) &&
        !this.nativeControls
        ? true
        : false;
    },
    showPlay() {
      return this.showControls || this.showPostControls;
    },
    showPause() {
      return this.showControls;
    },
    showVolume() {
      if (iOS()) {
        return false;
      }
      return this.showControls || this.showPostControls;
    },
    showVolumeMuted() {
      return this.showControls || this.showPostControls;
    }
  },
  data() {
    return {
      videoMode: false,
      playing: false,
      mute: false,
      id: uuidv4(),
      showControlsClass: false,
      showingCaptions: false
    };
  },
  watch: {
    forcePause(val) {
      if (val) {
        this.pause();
      }
    },
    videoUrl() {
      this.resetComponent();
    }
  },
  mounted() {
    const player = this.$refs.video;
    player.addEventListener("ended", () => {
      this.$emit("playbackFinished");
      this.resetComponent();
    });
    if (this.coverImgUrl === "" || this.autoplay) {
      this.videoMode = true;
      const videoBox = this.$refs.videoBox;
      videoBox.classList.remove("visible-cover");
      videoBox.classList.remove("invisible");
    }
    if (this.autoplay) {
      this.play();
    }
    player.addEventListener("loadedmetadata", () => {
      this.$emit("videoDurationUpdated", player.duration);
    });
  },
  beforeDestroy() {
    const player = this.$refs.video;
    player.removeEventListener("ended", () => {
      this.resetComponent();
      this.showControlsClass = true;
    });
  },
  methods: {
    async showTempControls() {
      this.showControlsClass = true;
      setTimeout(() => {
        if (this.playing) {
          this.showControlsClass = false;
        }
      }, 2000);
    },
    showCaption(show = true) {
      const captionIndex = 0;
      const vid = this.$refs.video;
      let track = vid.textTracks && vid.textTracks[0];
      // https://developer.mozilla.org/en-US/docs/Web/API/TextTrack/mode
      if (track) {
        // for (let index = 0; index < vid.textTracks.length; index++) {
        //   vid.textTracks[index].mode = "hidden";
        // }
        if (show) {
          if (vid.textTracks[captionIndex].mode === "showing") {
            vid.textTracks[captionIndex].mode =
              "hidden"; /* hidden | showing | disabled */
            this.showingCaptions = false;
          } else {
            vid.textTracks[captionIndex].mode =
              "showing"; /* hidden | showing | disabled */
            this.showingCaptions = true;
          }
        } else {
          vid.textTracks[captionIndex].mode =
            "hidden"; /* hidden | showing | disabled */
          this.showingCaptions = true;
        }
      }
      this.showControlsClass = false;
    },
    resetComponent() {
      this.pause();
      this.id = uuidv4();
      if (this.coverImgUrl != "") {
        this.videoMode = false;
        const videoBox = this.$refs.videoBox;
        videoBox.classList.add("visible-cover");
        videoBox.classList.remove("hidden-cover");
      }
      // this.showCaption(false);
    },
    play() {
      const player = this.$refs.video;
      this.videoMode = true;
      this.playing = true;
      const videoBox = this.$refs.videoBox;
      videoBox.classList.remove("visible-cover");
      videoBox.classList.add("hidden-cover");
      player.volume = this.$store.getters["general/getVolume"];
      this.mute = player.volume == 0;
      this.$emit("videoPlaying");
      player.play().catch(() => {
        this.playing = false;
        this.$emit("videoPlaying", false);
      });
    },
    pause() {
      const player = this.$refs.video;
      this.playing = false;
      player.pause();
    },
    setMute() {
      this.mute = true;
      const player = this.$refs.video;
      player.volume = 0;
      this.$store.dispatch("general/updateVolume", player.volume);
    },
    unmute() {
      this.mute = false;
      const player = this.$refs.video;
      player.volume = 1;
      this.$store.dispatch("general/updateVolume", player.volume);
    }
  }
};
</script>
<style lang="postcss" scoped>
video::-internal-media-controls-overlay-cast-button {
  display: none;
}

.bg-controls {
  width: 100%;
  height: 80px;
  bottom: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
}

@keyframes darker-out {
  0% {
    opacity: 1;
    filter: brightness(100%);
    visibility: visible;
  }

  80% {
    opacity: 1;
    filter: brightness(0%);
    visibility: visible;
  }

  100% {
    opacity: 0;
    filter: brightness(0%);
    visibility: hidden;
  }
}

@keyframes darker {
  0% {
    opacity: 0;
    filter: brightness(0%);
  }

  100% {
    opacity: 1;
    filter: brightness(100%);
  }
}

.visible-cover {
  animation: darker-out 1s;
}

.hidden-cover {
  visibility: visible;
  animation: darker 1.5s;
}

.center-play-btn {
  @apply top-1/2 left-1/2;
  transform: translate(-50%, -50%);
}
.is-desktop.hide-controls:not(:hover) {
  .bg-controls {
    animation: hide-controls-animation 0.5s;
    opacity: 0;
    filter: brightness(0%);
  }
  #video-controls {
    animation: hide-controls-animation 0.5s;
    display: none;
  }
}
.is-mobile.hide-controls {
  .bg-controls {
    animation: hide-controls-animation 0.5s;
    opacity: 0;
    filter: brightness(0%);
  }
  #video-controls {
    animation: hide-controls-animation 0.5s;
    display: none;
  }
}

@keyframes hide-controls-animation {
  0% {
    opacity: 1;
    filter: brightness(100%);
  }
  100% {
    opacity: 0;
    filter: brightness(0%);
  }
}
::cue {
  font-size: 20px;
  opacity: 1;
}
</style>
